import React from "react"
import PropTypes from "prop-types"

export const MenuCategories = props => {
  return (
    <>
      <ul className="menuCategory">
        {props.menuCategory.map((menu, index1) => (
          <li key={index1}>
            <section>
              {({ isSelected }) => (
                <a
                  href={`cadres/#menu-${index1}`}
                  className={`navbar-item ${isSelected ? `is-active` : ``}`}
                  onClick={evt => {
                    evt.preventDefault()
                    window.scrollTo({
                      top: window.document.querySelector(`#menu-${index1}`).offsetTop,
                      left: 0,
                      behavior: "smooth",
                    })
                  }}
                >
                  {menu.node.cat_libelle}
                </a>
              )}
            </section>
          </li>
        ))}
      </ul>
    </>
  )
}

MenuCategories.propTypes = {
  colorAdd: PropTypes.object,
  colorSelected: PropTypes.number,
  menuCategory: PropTypes.array,
}

export default MenuCategories
