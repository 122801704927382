import React, { useState } from "react"
import Layout from "../components/layout"
import PropTypes from "prop-types"
import { Link } from "gatsby-plugin-spinner"
import SEO from "./../components/seo"

import ImgBaguette from "../components/imgBaguette"
import PrevisuCoinCadre from "./../components/previsuCoinCadre"
import MenuCategories from "../components/menuCategories"
import { select_categories, select_couleur } from "../services/selectNiveaux"
import slugify from "slugify"
import Croquis from "../components/croquis"
import Search from "../components/search"

export const Familys = props => {
  const products = props.pageContext.products
  const couleurs = select_couleur(products, products).reverse()

  const [color, setColor] = useState(0) // 0 : all

  const get_families_rupture = products => {
    let families_available = []
    products.forEach(node => {
      if (typeof families_available[node.fa_id + "-" + node.ba_libelle_france_cadre] == "undefined")
        families_available[node.fa_id + "-" + node.ba_libelle_france_cadre] = true
      if (node.ba_continue !== 2) {
        // rupture
        families_available[node.fa_id + "-" + node.ba_libelle_france_cadre] = false
      }
    })
    return families_available
  }
  const rupture = useState(get_families_rupture(products))

  // Filtrage des produits par couleur :
  let productsByColor = products
  if (color !== 0) {
    productsByColor = products.filter(u => u.cog_id === color)
  } else {
    productsByColor = products
  }

  const groupe_product_familly = products => {
    let familleProduct = []
    let familleProductExist = []
    products.map(node => {
      if (!familleProductExist.includes(`${node.cat_id}-${node.fa_id}-${node.ba_libelle_france_cadre}`)) {
        familleProductExist.push(`${node.cat_id}-${node.fa_id}-${node.ba_libelle_france_cadre}`)
        familleProduct.push(node)
      }
    })
    return familleProduct
  }
  const productsBundles = groupe_product_familly(productsByColor)
  const menuCategory = select_categories(productsBundles)

  return (
    <>
      <Layout banner={false} location={props.location}>
        <SEO
          title={
            typeof props.pageContext.category !== "undefined"
              ? props.pageContext.category.cat_libelle
              : "Cadres de couleur " + props.pageContext.color?.toLowerCase()
          }
          description="Commandez vos cadres sur mesures. Bas prix pour les grosses commandes. Idéal grands comptes"
        />


          <div
            style={{
              position: "fixed",
              background: "#fff",
              top: "4.4rem",
              left: "0",
              right: "0",
              zIndex: 2,
              boxShadow: "2px 2px 2px #ddd",
            }}
          >
            <div className="container is-fluid">
              <div className="columns is-gapless is-multiline">
                <div className={`column is-${couleurs.length > 5 ? 3 : 5}`}>
                  <h3
                    style={{
                      marginTop: "19px",
                      marginBottom: 10,
                      fontSize: "1.1em",
                      color: "#555",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Link to="/cadres">
                      <span className="chevron left"></span>Cadres
                    </Link>{" "}
                    /{" "}
                    {typeof props.pageContext.color !== "undefined" ? (
                      <>Couleur / {props.pageContext.color} </>
                    ) : typeof props.pageContext.category !== "undefined" ? (
                      <>Catégorie / {props.pageContext.category.cat_libelle} </>
                    ) : (
                      ""
                    )}
                  </h3>
                </div>
                <div className="column is-4 is-hidden-desktop-only is-hidden-touch RemoveTBMarginPaddingMobile">
                  <Search resultsover={true} />
                </div>
                {typeof props.pageContext.color === "undefined" && couleurs.length > 1 ? (
                  <div
                    className={`column is-${couleurs.length > 5 ? 5 : 3} is-hidden-desktop-only is-hidden-touch`}
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      marginTop: 10,
                    }}
                  >
                    {couleurs.map(({ node }, indexColor) => (
                      <div key={indexColor}>
                        {node.cog_image_ba_id !== null ? (
                          <>
                            <div
                              title={node.cog_libelle}
                              className={`couleur ${color == node.cog_id ? "colorSelected" : ""}`}
                              onClick={() => setColor(node.cog_id)}
                              onKeyPress={() => {}}
                              tabIndex={0}
                              role="button"
                            >
                              <p className={`${color == node.cog_id ? "colorSelectedTitle" : "colorSelectedTitleVide"}`}>
                                {color == node.cog_id ? node.cog_libelle : ""}
                              </p>
                              <ImgBaguette
                                style={{
                                  height: "100%",
                                  marginTop: "0px",
                                }}
                                id={node.cog_image_ba_id}
                                type="coin"
                                alt={"couleur"}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              title={node.cog_libelle}
                              style={{ backgroundColor: node.cog_hexa }}
                              className={`couleur ${color == node.cog_id ? "colorSelected" : ""}`}
                              onClick={() => setColor(node.cog_id)}
                              onKeyPress={() => {}}
                              tabIndex={0}
                              role="button"
                            >
                              <p className={`${color == node.cog_id ? "colorSelectedTitle" : "colorSelectedTitleVide"}`}>
                                {color == node.cog_id ? node.cog_libelle : ""}
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="columns is-variable is-8 marge_top_page_famille">
            <div className={(typeof props.pageContext.color !== "undefined" ? "is-9" : "") + " column family"}>
              <div className="is-hidden-widescreen">
                {typeof props.pageContext.color === "undefined" && couleurs.length > 1 ? (
                  <div className="" style={{ textAlign: "center" }}>
                    <div className="dropdown is-hoverable">
                      <div className="dropdown-trigger">
                        <button className="button" aria-haspopup="true" aria-controls="dropdown-menu4">
                          <span>Filtrer par couleur</span>
                          <span className="icon is-small">
                            <i className="fas fa-angle-down" aria-hidden="true"></i>
                          </span>
                        </button>
                      </div>
                      <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                        <div className="dropdown-content">
                          {couleurs.map(({ node }, indexColor) => (
                            <div
                              className={`dropdown-item ${color == node.cog_id ? "is-active" : ""}`}
                              key={indexColor}
                              style={{ textAlign: "left" }}
                              onClick={() => setColor(node.cog_id)}
                              onKeyPress={() => {}}
                              role="button"
                              tabIndex={0}
                            >
                              {node.cog_image_ba_id !== null ? (
                                <>
                                  <div title={node.cog_libelle} className={`couleur`} style={{ display: "inline-block" }} role="button">
                                    <ImgBaguette
                                      style={{
                                        height: "100%",
                                        marginTop: "0px",
                                      }}
                                      id={node.cog_image_ba_id}
                                      type="coin"
                                      alt={"couleur"}
                                    />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    title={node.cog_libelle}
                                    style={{
                                      backgroundColor: node.cog_hexa,
                                      display: "inline-block",
                                    }}
                                    className={`couleur`}
                                  ></div>
                                </>
                              )}
                              <p
                                style={{
                                  verticalAlign: "66%",
                                  margin: 0,
                                  display: "inline-block",
                                }}
                              >
                                {node.cog_libelle}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <Search />
              </div>

              {menuCategory.length === 0 ? "Il n'y a aucun produit dans cette catégorie" : ""}
              {menuCategory.map((menu, index1) => (
                <section id={`menu-${index1}`} key={index1}>
                  <h2>{menu.node.cat_libelle}</h2>
                  <div className="family-grid">
                    {productsBundles
                      .filter(u => u.cat_id == menu.node.cat_id && u.fa_id !== 535 && u.fa_id !== 536)
                      .sort((a, b) => a.ba_prix - b.ba_prix)
                      .map((familly, index) => {
                        let baguette_doublo = familly.doublo_defaut
                        return (
                          <div key={index} className={rupture[familly.fa_id + "-" + familly.ba_libelle_france_cadre] ? "rupture" : ""}>
                            <Link to={`/cadres/${slugify(familly.cat_libelle.replace('(', '').replace(')', '').replace('...', ''))}-${slugify(familly.fa_libelle)}-${slugify(familly.co_libelle)}-${familly.ba_id}`}>
                              <div style={{ minWidth: "80px" }}>
                                <h4>{familly.fa_libelle}</h4>
                                <div className="nuances">
                                  {familly.nmbre_color === 1 ? "" : familly.nmbre_color > 1 ? familly.nmbre_color + " finitions" : ""}
                                </div>
                                <Croquis
                                  url={familly.croquis_svg !== null ? familly.croquis_svg : familly.croquis}
                                  feuillure={baguette_doublo === null ? familly.ba_hauteur_feuillure : baguette_doublo.ba_hauteur_feuillure}
                                  product={familly}
                                  largeurFixe={70}
                                  tailleCote={0}
                                  rehausse={null}
                                  couleurImage={"black"}
                                  couleurCotes={"#a2388d"}
                                  hideText={true}
                                  font={"Georgia"}
                                  fontSize={12}
                                  doublo={baguette_doublo}
                                  showHauteurFeuillure={
                                    typeof props.pageContext.category !== "undefined" && ![18, 3].includes(props.pageContext.category.cat_id)
                                  }
                                />
                                <br />
                                <b>
                                  <p className={"DetailCroquis"}>
                                    Largeur :{" "}
                                    {(baguette_doublo !== null ? familly.ba_talon + baguette_doublo.ba_largeur : familly.ba_largeur)
                                      .toFixed(2)
                                      .replace(/(\.0|0)+$/, "")}
                                    cm
                                  </p>
                                </b>
                                <p className={"DetailCroquis"}>
                                  Epaisseur :{" "}
                                  {(baguette_doublo !== null
                                    ? familly.ba_hauteur - familly.ba_hauteur_feuillure + baguette_doublo.ba_hauteur
                                    : familly.ba_hauteur
                                  )
                                    .toFixed(2)
                                    .replace(/(\.0|0)+$/, "")}
                                  cm
                                </p>
                              </div>
                              <div className="cadreFamille" style={{ padding: "3px" }}>
                                {familly.ba_fk_id_style === 47 && familly.cat_id !== 17 ? <div className="plaquage">Plaquage véritable bois</div> : ""}
                                <PrevisuCoinCadre
                                  width={200}
                                  height={80}
                                  ba_largeur={familly.ba_largeur > 39 ? 10 : familly.ba_largeur}
                                  idProduct={familly.ba_id}
                                  baguetteInterieure={baguette_doublo}
                                />
                              </div>
                            </Link>
                          </div>
                        )
                      })}
                  </div>
                  <div className="bottom-family"></div>
                </section>
              ))}
            </div>

            {typeof props.pageContext.color !== "undefined" ? (
              <div className="column is-3 is-fullheight has-background-grey-lighter menuDesktop">
                <MenuCategories colorSelected={color} menuCategory={menuCategory} />
              </div>
            ) : (
              ""
            )}
          </div>
      </Layout>
    </>
  )
}

Familys.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
}

export default Familys
